import { ElementRef, Renderer2 } from "@angular/core";

export interface ImageRender {
  element: ElementRef;
  renderer: Renderer2;
  attribute: string;
  render: (url: string) => void;
}

export abstract class ImageRenderModel implements ImageRender {
  element: ElementRef<any>;
  renderer: Renderer2;
  attribute!: string;

  constructor(el: ElementRef, renderer: Renderer2) {
    this.element = el;
    this.renderer = renderer;
  }

  abstract render(url: string): void;
}

export class HTMLImageRender extends ImageRenderModel {
  override attribute = 'src';

  override render(url: string): void {
    this.renderer.setAttribute(this.element.nativeElement, this.attribute, url);
  }
}
export class HTMLDivRender extends ImageRenderModel {
  override attribute = 'background-image';

  render(url: string): void {
    // not work: background-image: url(https://m4storagedev.blob.core.windows.net/devstoragecontainer/organization-1/business-unit-1/asset/844256c3-72d6-41ce-a56a-b646765c43a8-01 Purple-Dark.png?se=2023-07-13T09%3A20%3A57Z&sp=r&sv=2018-03-28&sr=b&sig=LhWmq%2BCyj2vRQxxOeO8SivpGd2vVXxx8vvvkmUZ69L4%3D);
    // work: background-image: url(https://randomuser.me/api/portraits/thumb/men/99.jpg);
    // url = 'https://m4storagedev.blob.core.windows.net/devstoragecontainer/organization-1/business-unit-1/asset/844256c3-72d6-41ce-a56a-b646765c43a8-01 Purple-Dark.png?se=2023-07-13T09%3A20%3A57Z&sp=r&sv=2018-03-28&sr=b&sig=LhWmq%2BCyj2vRQxxOeO8SivpGd2vVXxx8vvvkmUZ69L4%3D'
    // console.log('render ----> ',this.element.nativeElement,`url(${url})`)
    this.renderer.setStyle(this.element.nativeElement, this.attribute, `url("${url}")`);
    // this.element.nativeElement.style.backgroundImage = `url("${url}")`
  }
}
// background-image: url(https://m4storagedev.blob.core.windows.net/devstoragecontainer/organization-1/business-unit-1/asset/844256c3-72d6-41ce-a56a-b646765c43a8-01 Purple-Dark.png?se=2023-07-13T09%3A20%3A57Z&sp=r&sv=2018-03-28&sr=b&sig=LhWmq%2BCyj2vRQxxOeO8SivpGd2vVXxx8vvvkmUZ69L4%3D);
//
