export function parseQueryString(queryString: string): Record<string, string> {
  const data: Record<string, string> = {};

  if (queryString === null) {
    return data;
  }

  let pair, separatorIndex, escapedKey, escapedValue, key, value;
  const pairs = queryString.split('&');

  for (let i = 0; i < pairs.length; i++) {
    pair = pairs[i];
    separatorIndex = pair.indexOf('=');

    if (separatorIndex === -1) {
      escapedKey = pair;
      escapedValue = null;
    } else {
      escapedKey = pair.substring(0, separatorIndex);
      escapedValue = pair.substring(separatorIndex + 1);
    }

    key = decodeURIComponent(escapedKey);
    value = escapedValue ? decodeURIComponent(escapedValue) : '';

    if (key.substring(0, 1) === '/') {
      key = key.substring(1);
    }

    data[key] = value;
  }

  return data;
}
export function getHashFragmentParams(
  customHashFragment?: string
): Record<string, string> {
  let hash = customHashFragment || window.location.hash;

  hash = decodeURIComponent(hash);

  if (hash.indexOf('#') !== 0) {
    return {};
  }

  const questionMarkPosition = hash.indexOf('?');

  if (questionMarkPosition > -1) {
    hash = hash.substring(questionMarkPosition + 1);
  } else {
    hash = hash.substring(1);
  }

  return parseQueryString(hash);
}
