export type EventType =
  | 'discovery_document_loaded'
  | 'jwks_load_error'
  | 'invalid_nonce_in_state'
  | 'discovery_document_load_error'
  | 'discovery_document_validation_error'
  | 'user_profile_loaded'
  | 'user_profile_load_error'
  | 'token_received'
  | 'token_error'
  | 'code_error'
  | 'token_refreshed'
  | 'token_refresh_error'
  | 'silent_refresh_error'
  | 'silently_refreshed'
  | 'silent_refresh_timeout'
  | 'token_validation_error'
  | 'token_expires'
  | 'session_changed'
  | 'session_error'
  | 'session_terminated'
  | 'session_unchanged'
  | 'logout'
  | 'popup_closed'
  | 'popup_blocked'
  | 'token_revoke_error'
  | 'invalid_code_verifier_in_state';

export abstract class RedocOAuthEvent {
  constructor(readonly type: EventType) {}
}

export class RedocOAuthSuccessEvent extends RedocOAuthEvent {
  constructor(type: EventType, readonly info: any = null) {
    super(type);
  }
}

export class RedocOAuthInfoEvent extends RedocOAuthEvent {
  constructor(type: EventType, readonly info: any = null) {
    super(type);
  }
}

export class RedocOAuthErrorEvent extends RedocOAuthEvent {
  constructor(
    type: EventType,
    readonly reason: Record<string, any> | null = null,
    readonly params: Record<string, any> | null = null
  ) {
    super(type);
  }
}
