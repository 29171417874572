import { base64UrlEncode } from '../base64';

export async function createNonce(): Promise<string> {
  return new Promise((resolve) => {
    // if (this.rngUrl) {
    //   throw new Error(
    //     'createNonce with rng-web-api has not been implemented so far'
    //   );
    // }

    /*
     * This alphabet is from:
     * https://tools.ietf.org/html/rfc7636#section-4.1
     *
     * [A-Z] / [a-z] / [0-9] / "-" / "." / "_" / "~"
     */
    const unreserved =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    let size = 45;
    let id = '';

    const crypto = typeof self !== 'undefined' ? self.crypto : self['msCrypto'];
    if (crypto) {
      const bytes = new Uint8Array(size);
      crypto.getRandomValues(bytes);

      // Needed for IE
      if (!bytes.map) {
        (bytes as any).map = Array.prototype.map;
      }

      const bytesArray: number[] = Array.from(bytes).map((x) => {
        const index = (x % unreserved.length) as number;
        return unreserved.charCodeAt(index);
      });
      id = String.fromCharCode(...bytesArray);
    } else {
      while (0 < size--) {
        id += unreserved[(Math.random() * unreserved.length) | 0];
      }
    }

    resolve(base64UrlEncode(id));
  });
}
