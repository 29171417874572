import { Component, Input, Type, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilderContainerDirective } from './form-builder-container.directive';
import { REDOC_FORM_BUILDER_CONTROLLERS, builtInFormControllerPlugins } from './controllers';
import { FormControllerBase } from './controllers/form-controller.base';

@Component({
  selector: 'redoc-form-builder',
  standalone: true,
  imports: [CommonModule,FormBuilderContainerDirective],
  templateUrl: './form-builder.component.html',
  styleUrl: './form-builder.component.scss',
  providers:[
    {
      provide: REDOC_FORM_BUILDER_CONTROLLERS,
      useValue: builtInFormControllerPlugins.reduce((obj: Record<string, Type<FormControllerBase>>, ctor) => {
        obj[ctor.prototype.controlType] = ctor;
        return obj;
      }, {}),
    },
  ]
})
export class FormBuilderComponent {
  private _configs: any;
  @Input() get configs(){
    return this._configs
  }
  set configs(val){
    this._configs = val
  }
  @ViewChild(FormBuilderContainerDirective, { static: true }) container!: FormBuilderContainerDirective;
  renderForm():void{

  }
}
