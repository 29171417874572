import { TokenResponseKey } from './token-response';

export interface IRedocOauth2Config {
  /**
   * Url of the Identity Provider
   */
  issuer: string;
  /**
   * The client's id as registered with the auth server
   */
  clientId: string;
  /**
   * The client's redirectUri as registered with the auth server
   */
  redirectUri: string;
  /**
   * Defines whether additional debug information should
   * be shown at the console. Note that in certain browsers
   * the verbosity of the console needs to be explicitly set
   * to include Debug level messages.
   */
  showDebugInformation: boolean;
  decreaseExpirationBySec: number;
  /**
   * The window of time (in seconds) to allow the current time to deviate when validating id_token's iat and exp values.
   */
  clockSkewInSec?: number;
  responseType: 'code' | 'id_token' | 'token';
  preserveRequestedRoute: boolean;
  customQueryParams?: Record<string, string>;
  scope: string;
  loginUrl: string;
  logoutUrl: string;
  authorizeUrl: string;
  timeoutFactor: number;
  /**
   * Defines whether https is required.
   * The default value is remoteOnly which only allows
   * http for localhost, while every other domains need
   * to be used with https.
   */
  requireHttps?: boolean | 'remoteOnly';
  /**
   * An optional second redirectUri where the auth server
   * redirects the user to after logging out.
   */
  postLogoutRedirectUri: string;
  /**
   * Defines whether to use 'redirectUri' as a replacement
   * of 'postLogoutRedirectUri' if the latter is not set.
   */
  redirectUriAsPostLogoutRedirectUriFallback: boolean;
  nonceStateSeparator: string;

  /**
   * The organization to log in to.
   *
   * This will specify an `organization` parameter in your user's login request.
   *
   * - If you provide an Organization ID (a string with the prefix `org_`), it will be validated against the `org_id` claim of your user's ID Token. The validation is case-sensitive.
   * - If you provide an Organization Name (a string *without* the prefix `org_`), it will be validated against the `org_name` claim of your user's ID Token. The validation is case-insensitive.
   *
   */
  organization?: string;

  tokenSetConfig?: {
    prefix: string;
  };
}
export class RedocOauth2Config implements IRedocOauth2Config {
  issuer = '';
  loginUrl = '';
  organization = '';
  /**
   * The logout url.
   */
  logoutUrl = '';
  authorizeUrl = '';
  tokenEndpoint!: string;
  scope = '';
  responseType: 'code' | 'id_token' | 'token' = 'code';
  clientId!: string;
  /**
   * This property allows you to override the method that is used to open the login url,
   * allowing a way for implementations to specify their own method of routing to new
   * urls.
   */

  redirectUri = '';
  /**
   * Name of the iframe to use for session checks
   */
  showDebugInformation = false;
  clockSkewInSec?: number;
  /**
   * Set this to true to preserve the requested route including query parameters after code flow login.
   * This setting enables deep linking for the code flow.
   */
  public preserveRequestedRoute = false;
  public sessionCheckIFrameName? = 'oauth2-redoc-check-session-iframe';
  /**
   * Defines whether https is required.
   * The default value is remoteOnly which only allows
   * http for localhost, while every other domains need
   * to be used with https.
   */
  public requireHttps?: boolean | 'remoteOnly' = 'remoteOnly';
  constructor(json?: Partial<IRedocOauth2Config>) {
    if (json) {
      Object.assign(this, json);
    }
    console.log('json -->', json);
    this.tokenEndpoint = this.issuer + '/token';
  }
  /**
   * Allows to disable the timer for the id_token used
   * for token refresh
   */
  public disableIdTokenTimer? = false;
  /**
   * Decreases the Expiration time of tokens by this number of seconds
   */
  public decreaseExpirationBySec = 0;
  /**
   * Defines when the token_timeout event should be raised.
   * If you set this to the default value 0.75, the event
   * is triggered after 75% of the token's life time.
   */
  public timeoutFactor = 0.75;
  /**
   * final state sent to issuer is built as follows:
   * state = nonce + nonceStateSeparator + additional state
   * Default separator is ';' (encoded %3B).
   * In rare cases, this character might be forbidden or inconvenient to use by the issuer so it can be customized.
   */
  public nonceStateSeparator = ';';
  /**
   * Defines whether to request an access token during
   * implicit flow.
   */
  public requestAccessToken = true;
  /**
   * If true, the lib will try to check whether the user
   * is still logged in on a regular basis as described
   * in http://openid.net/specs/openid-connect-session-1_0.html#ChangeNotification
   */
  public sessionChecksEnabled? = false;
  /**
   * Defined whether to skip the validation of the issuer in the discovery document.
   * Normally, the discovey document's url starts with the url of the issuer.
   */
  public skipIssuerCheck = false;
  /**
   * This property has been introduced to disable at_hash checks
   * and is indented for Identity Provider that does not deliver
   * an at_hash EVEN THOUGH its recommended by the OIDC specs.
   * Of course, when disabling these checks then we are bypassing
   * a security check which means we are more vulnerable.
   */
  public disableAtHashCheck = false;
  /**
   * According to rfc6749 it is recommended (but not required) that the auth
   * server exposes the access_token's life time in seconds.
   * This is a fallback value for the case this value is not exposed.
   */
  public fallbackAccessTokenExpirationTimeInSec?: number;
  /**
   * Map with additional query parameter that are appended to
   * the request when initializing implicit flow.
   */
  public customQueryParams: Record<string, string> = {};
  /**
   * Defines whether to clear the hash fragment after logging in.
   */
  public clearHashAfterLogin = true;

  /**
   * Names of known parameters sent out in the TokenResponse. https://tools.ietf.org/html/rfc6749#section-5.1
   */
  public customTokenParameters: TokenResponseKey[] = [];
  /**
   * An optional second redirectUri where the auth server
   * redirects the user to after logging out.
   */
  public postLogoutRedirectUri = '';
  /**
   * Defines whether to use 'redirectUri' as a replacement
   * of 'postLogoutRedirectUri' if the latter is not set.
   */
  public redirectUriAsPostLogoutRedirectUriFallback = true;
}
