import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControllerBase } from '../form-controller.base';
import { IFormControllerType } from '../../interfaces';

@Component({
  selector: 'redoc-text-controller',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './text-controller.component.html',
  styleUrl: './text-controller.component.scss',
})
export class TextControllerComponent extends FormControllerBase {
  override get controlType(): IFormControllerType {
    return 'text'
  }
}
