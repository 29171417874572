export class ErrorBase extends Error {
  constructor(public error: string, public errorDescription: string) {
    super(errorDescription);
    Object.setPrototypeOf(this, ErrorBase.prototype);
  }
  static fromPayload({
    error,
    error_description,
  }: {
    error: string;
    error_description: string;
  }) {
    return new ErrorBase(error, error_description);
  }
}

export class TimeoutError extends ErrorBase {
  constructor() {
    super('timeout', 'Timeout');
    //https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, TimeoutError.prototype);
  }
}
