import { Directive, ElementRef, EventEmitter, HostListener, Inject, Input, OnChanges, Output, Renderer2, SimpleChanges } from '@angular/core';
import { take, tap } from 'rxjs';
import { HTMLDivRender, HTMLImageRender, ImageRenderModel } from './image-loader.model';
import { IMAGE_LOADER_FN, ImageLoaderFn } from './image-loader.token';

@Directive({
  selector: '[redocImageElement]',
})
export class ImageElementDirective implements OnChanges {
  @Input() redocImageElement: string | undefined;
  @Output() emitError = new  EventEmitter<string>()
  private imageLoader: ImageRenderModel;
  loading = false;
  @HostListener('error')
  onLoadImageError() {
    const msg = 'image load fail';
    this.emitError.emit(msg)
    console.log(msg)
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2,@Inject(IMAGE_LOADER_FN) private imageLoaderFn:ImageLoaderFn) {
    if (this.elementRef.nativeElement instanceof HTMLImageElement) {
      this.imageLoader = new HTMLImageRender(this.elementRef, this.renderer);
    } else {
      this.imageLoader = new HTMLDivRender(this.elementRef, this.renderer);
    }
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['redocImageElement'].currentValue && changes['redocImageElement'].currentValue !== changes['redocImageElement'].previousValue) {
      this.loading = false;
      this.imageLoaderFn(changes['redocImageElement'].currentValue).pipe(
        tap(()=>{
          this.loading = true;
        }),
        take(1),
      ).subscribe({
        next: (info: string) => {
          this.imageLoader.render(info);
        },
        error: () => {
          const msg = 'image fetch fail';
          this.loading = false;
          this.emitError.emit(msg)
        },
      });
    }
  }
}
