import { CookieOptions } from 'ngx-cookie';
export type RedocAuthStorageType =
  | 'cookie'
  | 'localStroge'
  | 'session'
  | 'transferState';
export type RedocAuthStorageOptions = CookieOptions;
export interface RedocAuthStorage {
  type: RedocAuthStorageType;
  setItem(key: string, value: string, options?: RedocAuthStorageOptions): void;
  getItem(key: string): string | null;
  removeItem(key: string): void;
  clear(): void;
}
