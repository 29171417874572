export interface RedocDiscoveryDoc {
  authorization_endpoint: string;
  end_session_endpoint: string;
  grant_types_supported: string;
  issuer: string;
  token_endpoint: string;
  userinfo_endpoint: string;
  jwks_uri: string;
  check_session_iframe: string;
  revocation_endpoint: string;
  session?: {
    prefix?: string;
  };
}
