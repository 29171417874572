import { AfterContentInit, Directive, OnDestroy, OnInit } from "@angular/core";
import { IFormControllerType } from "../interfaces";

@Directive()
export abstract class FormControllerBase implements OnInit, AfterContentInit, OnDestroy {
    abstract get controlType(): IFormControllerType;
    ngOnInit(): void {
        throw new Error("Method not implemented.");
    }
    ngAfterContentInit(): void {
        throw new Error("Method not implemented.");
    }
    ngOnDestroy(): void {
        throw new Error("Method not implemented.");
    }
    
}