import { Injectable, Type, inject } from "@angular/core";
import { HttpClient } from '@angular/common/http';

import { Translation, TranslocoLoader, provideTransloco, translocoConfig } from "@ngneat/transloco";
export interface TranslationConfigOptions{
  prodMode?:boolean;
  httpLoader?:Type<TranslocoLoader>
}
@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoaderDefault implements TranslocoLoader {
  private http = inject(HttpClient);

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

export function provideTranslation(configs:TranslationConfigOptions){
  return provideTransloco({
    config: translocoConfig({
      availableLangs: ['en', 'es'],
      defaultLang: 'en',
      fallbackLang: 'en',
      // Remove this option if your application
      // doesn't support changing language in runtime.
      reRenderOnLangChange: true,
      prodMode: configs?.prodMode,
    }),
    loader: configs.httpLoader ?? TranslocoHttpLoaderDefault,
  })
}
