<div class="flex flex-col flex-auto relative">
  <div class="z-10 top-0 layout-header flex flex-col w-full">
    <div
      class="h-full z-1 flex flex-row items-center justify-between bg-card text-gray"
    >
      <div class="flex flex-row items-center gap-2 px-2 w">
        <!-- <div class="flex flex-row items-center"> -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
          <mat-icon>menu</mat-icon>
        </button>
        <!-- </div> -->
        <a *ngIf="logoLabel" class="flex w-24 h-6" [routerLink]="['.']">
          <ng-template [cdkPortalOutlet]="logoLabel"></ng-template>
        </a>
      </div>
      <div class="flex flex-row items-center gap-6">
        <ng-template [ngIf]="templateLabel">
          <ng-template [cdkPortalOutlet]="templateLabel"></ng-template>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="relative flex flex-row flex-auto">
    <!-- Navigation -->
    <redoc-vertical-navigation
      #verticalNavigation
      *ngIf="navigation"
      class="dark bg-gray-900 print:hidden"
      [appearance]="naviagtionAppearance"
      [mode]="naviagtionMode"
      [name]="'mainNavigation'"
      [navigation]="navigation[naviagtionAppearance]!"
      [autoCollapse]="false"
      [opened]="naviagtionMode !== 'over'"
    >
      <ng-container redocVerticalNavigationHeader>
        <!-- Logo -->
        <!-- <div
          *ngIf="naviagtionMode === 'over'"
          class="flex flex-row items-center justify-between gap-2 px-4 bg-white"
        >
          <a *ngIf="logoLabel" class="flex w-24 h-6" [routerLink]="['.']">
            <ng-template [cdkPortalOutlet]="logoLabel"></ng-template>
          </a>
          <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon class="text-theme-dark">close</mat-icon>
          </button>
        </div>
        <div
          *ngIf="naviagtionMode !== 'over'"
          class="layout-header-space"
        ></div> -->
      </ng-container>
      <!-- Navigation header hook -->
    </redoc-vertical-navigation>
    <!-- Content -->
    <div class="flex flex-col flex-auto layout-content">
      <div class="layout-header-space"></div>
      <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
       Otherwise, layout changes won't be registered and the view won't be updated! -->
      <router-outlet *ngIf="true"></router-outlet>
    </div>
  </div>
</div>
