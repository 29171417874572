import { Injectable, inject } from '@angular/core';
import { REQUEST, RESPONSE } from '@shared/core/express';
import { isEmpty } from 'lodash-es';
import { CookieDict, isPresent, parseCookieString } from './auth.utils';
import { ICookieReader } from './cookie.service';

const COOKIE_SEPARATOR = ';';

@Injectable()
export class CookieServerReaderService implements ICookieReader {
  private request = inject(REQUEST, { optional: true });
  private response = inject(RESPONSE, { optional: true });
  /**
   * @description
   * Returns if the given cookie key exists or not.
   *
   * @param key Id to use for lookup.
   * @returns true if key exists, otherwise false.
   */
  hasKey(key: string): boolean {
    const value = this.get(key);
    return isPresent(value);
  }
  /**
   * @description
   * Returns the value of given cookie key.
   *
   * @param key Id to use for lookup.
   * @returns Raw cookie value.
   */
  get(key: string): string | undefined {
    const cookieDict = this.getAll();
    console.log('heeello backend', key, cookieDict, cookieDict[key]);
    return cookieDict[key];
  }
  readAllCookieAsString(): string {
    const requestHeadersCookies = this.request?.headers?.cookie;
    console.log('CookieBackendWriterService', requestHeadersCookies);
    const cookiesFromRequest: string[] =
      requestHeadersCookies?.split(COOKIE_SEPARATOR) ?? [];
    const addedCookies: string[] = this.getNormalizedResponseCookies();
    const allCookies = this.latestUniqueCookieValues(
      cookiesFromRequest,
      addedCookies
    );
    return allCookies.join(COOKIE_SEPARATOR);
  }
  /**
   * @description
   * Returns a key value object with all the cookies.
   *
   * @returns All cookies
   */
  getAll(): CookieDict {
    const cookieString = this.readAllCookieAsString();
    const dataState = (this.request as { redoc: any })['redoc'];
    console.log('dataState --> ', dataState);
    return parseCookieString(cookieString);
  }
  private getNormalizedResponseCookies(): string[] {
    const responseCookies =
      (this.response?.getHeader('Set-Cookie') as string | string[]) ?? '';
    const addedCookies: string[] = Array.isArray(responseCookies)
      ? responseCookies
      : [responseCookies];
    return addedCookies.flatMap((cookieEntry) =>
      cookieEntry.split(COOKIE_SEPARATOR)
    );
  }

  private latestUniqueCookieValues(
    oldCookies: string[],
    newerCookies: string[]
  ): string[] {
    const cookiesMap = new Map<string, string>();
    const oldAndNewCookies: string[] = [...oldCookies, ...newerCookies];
    oldAndNewCookies
      .filter((value) => !isEmpty(value))
      .map((cookie) => cookie.split('='))
      .forEach(([key, value]) => cookiesMap.set(key.trim(), value));
    const result: string[] = [];
    cookiesMap.forEach((value, key) => result.push(`${key}=${value}`));
    return result;
  }
}
