import { omitBy, pick } from 'lodash-es';
import {
  AuthorizationParams,
  AuthorizeOptions,
} from './models/authorize.model';
import { base64UrlEncode } from './base64';
import { IRedocOauth2Config, RedocOauth2Config } from './config';
import { bufferToBase64UrlEncoded, createRandomString, sha256 } from './crypto';
import { createQueryParams, getJSON } from './http';
import { LoginOptions } from './login-option';
import { createNonce } from './material/nonce';
import { createUrl } from './material/url';
import { TokenResponse } from './token-response';

// export async function _prepareAuthorizeUrl(
//   configs:IRedocOauth2Config,
//   authorizationParams: AuthorizationParams,
//   authorizeOptions?: Partial<AuthorizeOptions>,
//   fallbackRedirectUri?: string
// ): Promise<{
//   scope: string;
//   audience: string;
//   redirect_uri?: string;
//   nonce: string;
//   code_verifier: string;
//   state: string;
//   url: string;
// }> {
//   const state = base64UrlEncode(createRandomString());
//   const nonce = await createNonce();
//   const code_verifier = createRandomString();
//   const code_challengeBuffer = await sha256(code_verifier);
//   const code_challenge = bufferToBase64UrlEncoded(code_challengeBuffer);

//   const params = createCodeAuthorizationParams(
//     configs,
//     this.scope,
//     authorizationParams,
//     state,
//     nonce,
//     code_challenge,
//     authorizationParams.redirect_uri ||
//       this.options.authorizationParams.redirect_uri ||
//       fallbackRedirectUri,
//     authorizeOptions?.response_mode
//   );

//   const url = this._authorizeUrl(params);

//   return {
//     nonce,
//     code_verifier,
//     scope: params.scope,
//     audience: params.audience || 'default',
//     redirect_uri: params.redirect_uri,
//     state,
//     url,
//   };
// }
export async function createCodeAuthorizationParams(
  configs: IRedocOauth2Config,
  authorizationParams: AuthorizationParams,
  createNonceFn: () => Promise<string>,
  customState?: string | null,
  response_mode?: string
): Promise<AuthorizeOptions> {
  const nonce = await createNonceFn();
  const state = customState
    ? nonce + configs.nonceStateSeparator + encodeURIComponent(customState)
    : nonce;

  const code_verifier = createRandomString();
  const code_challengeBuffer = await sha256(code_verifier);
  const code_challenge = bufferToBase64UrlEncoded(code_challengeBuffer);

  const params: AuthorizeOptions = {
    client_id: configs.clientId,
    ...authorizationParams,
    response_type: configs.responseType || 'code',
    response_mode: response_mode || 'query',
    nonce,
    state,
    scope: configs.scope,
    code_challenge,
    code_challenge_method: 'S256',
    code_verifier,
    redirect_uri: configs.redirectUri,
  };
  return params;
}
export function createAndSaveNonce(localStorage: {
  setItem: (key: string, value: any) => void;
}): Promise<string> {
  return createNonce().then(function (nonce: any) {
    localStorage.setItem('nonce', nonce);
    return nonce;
  });
}
export function pickAvailabelAuthorizeParams(
  params: AuthorizeOptions
): Pick<
  AuthorizeOptions,
  | 'client_id'
  | 'response_type'
  | 'code_challenge'
  | 'code_challenge_method'
  | 'scope'
  | 'state'
  | 'redirect_uri'
  | 'display'
  | 'organization'
  | 'with_token'
> {
  return omitBy(
    pick(params, [
      'client_id',
      'response_type',
      'code_challenge',
      'code_challenge_method',
      'scope',
      'state',
      'redirect_uri',
      'display',
      'organization',
      'with_token',
    ]),
    (val) => !val
  ) as AuthorizeOptions;
}
export async function getTokenFromCode(
  payload: { code: string; codeVerifier: string },
  config: RedocOauth2Config,
  redirect_uri: string,
  worker?: Worker
) {
  const body = JSON.stringify({
    grant_type: 'authorization_code',
    code: payload.code,
    code_verifier: payload.codeVerifier,
    redirect_uri,
    ...config.customQueryParams,
  });
  const url = new URL(config.tokenEndpoint);
  // url.search = queries;
  return await getJSON<TokenResponse>(
    url.href,
    undefined,
    'default',
    config.scope,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    },
    worker,
    false
  );
}
