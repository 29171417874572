/* eslint-disable no-useless-escape */
import { RedocOauth2Config } from './config';
import { RedocOAuthLogger } from './logger';
import { RedocDiscoveryDoc } from './models/discovery-doc.model';

export class RedocOauthValidation {
  constructor(
    public config: Partial<RedocOauth2Config> = {},
    public logger?: RedocOAuthLogger
  ) {}
  updateValueAndValidiy(newConfig: Partial<RedocOauth2Config> = {}): void {
    Object.assign(this.config, newConfig);
  }
  validateUrlForHttps(url: string): boolean {
    if (!url) {
      return true;
    }

    const lcUrl = url.toLowerCase();

    if (this.config.requireHttps === false) {
      return true;
    }

    if (
      (lcUrl.match(/^http:\/\/localhost($|[:\/])/) ||
        lcUrl.match(/^http:\/\/localhost($|[:\/])/)) &&
      this.config.requireHttps === 'remoteOnly'
    ) {
      return true;
    }

    return lcUrl.startsWith('https://');
  }
  validateDiscoveryDocument(doc: RedocDiscoveryDoc):{valid:true} | {valid:false; msg:string} {
    let errors: string[];

    if (!this.config.skipIssuerCheck && doc.issuer !== this.config.issuer) {
      const msg = `invalid issuer in discovery document, expected: ${this.config.issuer}, current: ${doc.issuer}`
      this.logger?.error(msg);
      return {
        valid:false,
        msg
      };
    }

    errors = this.validateUrlFromDiscoveryDocument(doc.authorization_endpoint);
    if (errors.length > 0) {
      this.logger?.error(
        'error validating authorization_endpoint in discovery document',
        errors
      );
      const msg = `error validating authorization_endpoint in discovery document ${JSON.stringify(errors)}`;
      return {
        valid:false,
        msg
      };
    }

    errors = this.validateUrlFromDiscoveryDocument(doc.end_session_endpoint);
    if (errors.length > 0) {
      this.logger?.error(
        'error validating end_session_endpoint in discovery document',
        errors
      );
      const msg = `error validating end_session_endpoint in discovery document ${JSON.stringify(errors)}`;
      return {
        valid:false,
        msg
      };
    }

    errors = this.validateUrlFromDiscoveryDocument(doc.token_endpoint);
    if (errors.length > 0) {
      this.logger?.error(
        'error validating token_endpoint in discovery document',
        errors
      );

      const msg = `error validating token_endpoint in discovery document ${JSON.stringify(errors)}`;
      return {
        valid:false,
        msg
      };
    }

    errors = this.validateUrlFromDiscoveryDocument(doc.revocation_endpoint);
    if (errors.length > 0) {
      this.logger?.error(
        'error validating revocation_endpoint in discovery document',
        errors
      );

      const msg = `error validating revocation_endpoint in discovery document ${JSON.stringify(errors)}`;
      return {
        valid:false,
        msg
      };
    }

    errors = this.validateUrlFromDiscoveryDocument(doc.userinfo_endpoint);
    if (errors.length > 0) {
      this.logger?.error(
        'error validating userinfo_endpoint in discovery document',
        errors
      );
      const msg = `error validating userinfo_endpoint in discovery document ${JSON.stringify(errors)}`;
      return {
        valid:false,
        msg
      };
    }

    errors = this.validateUrlFromDiscoveryDocument(doc.jwks_uri);
    if (errors.length > 0) {
      this.logger?.error(
        'error validating jwks_uri in discovery document',
        errors
      );
      const msg = `error validating jwks_uri in discovery document ${JSON.stringify(errors)}`;
      return {
        valid:false,
        msg
      };
    }

    if (this.config.sessionChecksEnabled && !doc.check_session_iframe) {
      this.logger?.warn(
        'sessionChecksEnabled is activated but discovery document' +
          ' does not contain a check_session_iframe field'
      );
    }

    return {valid:true};
  }
  validateUrlFromDiscoveryDocument(url: string): string[] {
    const errors: string[] = [];
    const httpsCheck = this.validateUrlForHttps(url);
    const issuerCheck = this.validateUrlAgainstIssuer(url);

    if (!httpsCheck) {
      errors.push(
        'https for all urls required. Also for urls received by discovery.'
      );
    }

    if (!issuerCheck) {
      errors.push(
        'Every url in discovery document has to start with the issuer url.' +
          'Also see property strictDiscoveryDocumentValidation.'
      );
    }

    return errors;
  }
  validateUrlAgainstIssuer(url: string) {
    if (!this.config.issuer) {
      throw new Error('config.issuer is undefine');
    }
    // if (!this.config.strictDiscoveryDocumentValidation) {
    //   return true;
    // }
    if (!url) {
      return true;
    }
    return url.toLowerCase().startsWith(this.config.issuer.toLowerCase());
  }
}
// let validator: RedocOauthValidation;
export function createRedocOauthValidator(
  config: Partial<RedocOauth2Config> = {},
  logger?:RedocOAuthLogger
): RedocOauthValidation {
  // if (validator) {
  //   validator.updateValueAndValidiy(config);
  //   return validator;
  // }
  // validator = new RedocOauthValidation(config,logger);
  return new RedocOauthValidation(config,logger);
}
