import { Component, ViewEncapsulation } from '@angular/core';
import { LayoutBase } from './layout-base.directive';
import { EmptyLayoutComponent } from './layouts/empty-layout/empty-layout.component';
import { ClassicLayoutComponent } from './layouts/vertical/classic-layout/classic-layout.component';
import { CommonModule } from '@angular/common';
import { WellcomeLayoutComponent } from './layouts';

export const layoutComponents = [
  // Empty
  EmptyLayoutComponent,
  // Wellcome
  WellcomeLayoutComponent,
  // Horizontal navigation

  // Vertical navigation
  ClassicLayoutComponent,
];
@Component({
  selector: 'redoc-layout',
  standalone: true,
  imports: [CommonModule, ...layoutComponents],
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LayoutComponent extends LayoutBase {}
