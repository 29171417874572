import { ChangeDetectorRef, Component, ContentChildren, OnDestroy, OnInit, QueryList, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { UserItemContextDirective } from './user-context.directive';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { AUTH_USER_LOADER, IUser, UserAdapter } from '@shared/features/auth';

@Component({
  selector: 'redoc-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit, OnDestroy {
  @ViewChild('itemContainer', { read: ViewContainerRef, static: true }) itemContainer!: ViewContainerRef;
  @ContentChildren(UserItemContextDirective, { descendants: true, read: UserItemContextDirective })
  get children(): QueryList<UserItemContextDirective> | undefined {
    return this._children;
  }
  set children(value: QueryList<UserItemContextDirective> | undefined) {
    this._children = value;
    this.renderView();
  }

  public _children?: QueryList<UserItemContextDirective>;
  user: IUser | undefined;
  private _unsubscribeAll: Subject<void> = new Subject<void>();
  private _hasView = false;
  private _changeDetectorRef = inject(ChangeDetectorRef);
   private _router =  inject(Router);
   private _userService = inject(AUTH_USER_LOADER)

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to user changes
    this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: IUser) => {
      this.user = user;
      if (this.children) {
        for (const child of this.children) {
          child.context = { $implicit: user };
        }
      }
      // Mark for check
      this.renderView();
    });
  }
  renderView(): void {
    if (this._hasView) {
      this.itemContainer.clear();
      this._hasView = false;
    }
    if (this.children) {
      for (const child of this.children) {
        this.itemContainer.createEmbeddedView(child.templateRef, { $implicit: this.user });
      }
      this._hasView = true;
    }
    this._changeDetectorRef.markForCheck();
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
