import { HttpParams } from "@angular/common/http";
import { IApiOption } from "./api-option.interface";

export interface IApiConfig{
  apiHost:string;
  urlPrefix:string;
  timeout:number;
  appId:string;
}

export interface IApiDTO {
  data: Record<string, unknown>;
}
export type NormalizedApiParamsFn = (data:Record<string, any>,options?: IApiOption) => Record<string, any>
