import { AuthorizationParams } from './authorize.model';

export interface BaseLoginOptions {
  /**
   * URL parameters that will be sent back to the Authorization Server. This can be known parameters
   * defined by Auth0 or custom parameters that you define.
   */
  authorizationParams?: AuthorizationParams;
  disableNonceCheck?: boolean;
}
