import { Component, ContentChild, OnInit } from '@angular/core';
import { ImageElementDirective } from './image-loader.directive';

@Component({
  selector: 'redoc-image-loader',
  templateUrl: './image-loader.component.html',
  styleUrls: ['./image-loader.component.scss'],
})
export class ImageLoaderComponent {
  @ContentChild(ImageElementDirective, { static: true })
  imageElementDirective!: ImageElementDirective;
}
