export interface LoginOptions {
  onTokenReceived?: (receivedTokens: ReceivedTokens) => void;
  onLoginError?: (params: object) => void;
  customHashFragment?: string;
  preventClearHashAfterLogin?: boolean;
  /**
   * Set this to true to disable the nonce
   * check which is used to avoid
   * replay attacks.
   * This flag should never be true in
   * production environments.
   */
  disableNonceCheck?: boolean;
  /**
   * Set this to true to disable the oauth2 state
   * check which is a best practice to avoid
   * security attacks.
   * As OIDC defines a nonce check that includes
   * this, this can be set to true when only doing
   * OIDC.
   */
  disableOAuth2StateCheck?: boolean;
  /**
   * Set this for code flow if you used a custom redirect Uri
   * when retrieving the code. This is used internally for silent
   * refresh and popup flows.
   */
  customRedirectUri?: string;
}
/**
 * Represents the received tokens, the received state
 * and the parsed claims from the id-token.
 */
export interface ReceivedTokens {
  idToken: string;
  accessToken: string;
  idClaims?: object;
  state?: string;
}
