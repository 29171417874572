export interface ITokenKeyStoreSet {
  name: string;
  expiresAt: string;
  storedAt: string;
  claimsObj: string;
}
export class TokenKeyStoreSet implements ITokenKeyStoreSet {
  name!: string;
  expiresAt!: string;
  storedAt!: string;
  claimsObj!: string;
  constructor(name: string, prefix: string) {
    this.name = this.generateKey(name, prefix, '');
    this.expiresAt = this.generateKey(name, prefix, 'expires_at');
    this.storedAt = this.generateKey(name, prefix, 'stored_at');
    this.claimsObj = this.generateKey(name, prefix, 'claims_obj');
  }
  generateKey(key: string, prefix: string, suffix: string): string {
    return [prefix, key, suffix].filter((val) => !!val).join('_');
  }
}

export function createTokenKeyStoreSet(
  name: string,
  prefix = 'redoc'
): TokenKeyStoreSet {
  return new TokenKeyStoreSet(name, prefix);
}
