import { CdkPortal } from '@angular/cdk/portal';
import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[redocLogoHeader]',
  standalone: true,
})
export class LogoHeaderDirective extends CdkPortal{
  constructor(
    templateRef: TemplateRef<any>,
    viewContainerRef: ViewContainerRef,
  ){
    super(templateRef,viewContainerRef);
  }
}
