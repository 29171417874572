<div class="flex flex-col flex-auto min-w-0 overflow-hidden">
    <div class="flex-auto relative">
      <div class="absolute inset-0 flex flex-col min-w-0">
        <mat-drawer-container hasBackdrop="false" class="flex-auto h-full bg-card dark:bg-transparent">
          <!-- Drawer -->
          <mat-drawer
            (openedChange)="drawerOpenedChange($event)"
            class="detail-content dark:bg-gray-900"
            [mode]="drawerMode"
            [opened]="false"
            [position]="'end'"
            [disableClose]="true"
            #matDrawer
          >
            <div class="w-full h-full flex flex-col">
             <ng-content></ng-content>
            </div>
          </mat-drawer>
  
          <mat-drawer-content>
            <div class="flex flex-col flex-auto h-full overflow-hidden">
              <div class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-4 pl-4 pr-4">
                <div class="flex-1 min-w-0">
                  <div class="flex flex-wrap items-center">
                    <div>
                      <a class="whitespace-nowrap text-d text-theme-gray">{{ 'transaction' | transloco | titlecase }}</a>
                    </div>
                    <div class="flex items-center ml-1 whitespace-nowrap">
                      <span class="text-d text-theme-gray">/</span>
                      <a class="text-d text-theme-gray ml-1">{{ 'project_admin' | transloco | titlecase }}</a>
                    </div>
                    <div class="flex items-center ml-1 whitespace-nowrap">
                      <span class="text-d text-theme-gray">/</span>
                      <span class="text-d text-theme-dark ml-1">{{ 'project_transactions' | transloco | titlecase }}</span>
                    </div>
                  </div>
                  
                  
                  <div  class="flex flex-col flex-auto gap-3">
                    <div class="flex flex-row items-center">
                      <h2 class="text-h5 truncate font-bold">
                        {{ 'project_transactions' | transloco | titlecase }}
                      </h2>
                      <div class="flex flex-row flex-auto justify-end px-4 gap-4">
                       
                      
                      </div>
                    </div>
                    <!-- <div
                      *ngIf="isFiltering"
                      @expandCollapse
                      class="flex flex-col items-start flex-auto lg:flex-row lg:justify-between lg:items-center bg-theme-navy-blue px-4 py-2 rounded overflow-hidden"
                    >
                      <div class="flex flex-row items-center flex-wrap pl-6 gap-4">
                        <mat-form-field
                          class="w-40 fuse-mat-no-subscript fuse-mat-rounded-sm fuse-mat-dense fuse-search-box has-border fuse-mat-float-label-always"
                          appearance="fill"
                          floatLabel="always"
                        >
                          <mat-select matInput formControlName="status" placeholder="Status">
                            <mat-option value="">All</mat-option>
                            <mat-option *ngFor="let status of statusArray" [value]="status.value">{{ status.name | titlecase }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="flex flex-row items-center pr-6 text-white">
                        <button class="button" mat-button (click)="reset()">
                          <span class="ml-1">Clear Filters</span>
                        </button>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="mx-4 border border-b-0 border-theme-gray-border border-solid flex flex-col flex-auto overflow-hidden">
                <div class="relative flex flex-col flex-auto overflow-auto">
                  <mat-drawer-container class="h-full">
                    <mat-drawer #filterDrawer mode="side">
                      <div class="w-full h-full flex flex-col overflow-hidden">
                        <div  class="flex flex-auto flex-col p-4 gap-4 overflow-y-auto">
  
                            <redoc-form-builder></redoc-form-builder>
  
                        </div>
                        <div class="flex flex-row px-4 py-2">
                          <button type="button" mat-button (click)="filterDrawer.close()">Close</button>
                        </div>
                      </div>
                    </mat-drawer>
                   
                    <ng-template [redLoadingBox]="isLoading"></ng-template>
                  </mat-drawer-container>
                </div>
               
              </div>
            </div>
          </mat-drawer-content>
        </mat-drawer-container>
      </div>
    </div>
  </div>
  