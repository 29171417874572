export function createUrl(
  baseUrl: string,
  params: Record<string, any>
): string {
  // const seperationChar = baseUrl.indexOf('?') > -1 ? '&' : '?';
  const url = new URL(baseUrl);
  const querySearch = new URLSearchParams(params);
  url.search = querySearch.toString();
  // const paramString = Object.entries(params)
  //   .filter(([key, value]) => !!value)
  //   .map(([key, value]) => {
  //     return `${key}=${encodeURIComponent(value)}`;
  //   })
  //   .join('&');
  return url.href;
}
