import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable, of, tap } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthProvider } from '../auth.service';
import { AUTH_PROVIDER } from '../auth.token';

@Injectable()
export class SLOAuthGuard {
  _authService = inject(AUTH_PROVIDER);
  /**
   * Constructor
   */
  constructor(private _router: Router) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const redirectUrl = route.queryParams['continue'];
    const stateObj = route.queryParams['state'];
    return this._check(redirectUrl, stateObj);
  }

  /**
   * Can activate child
   *
   * @param childRoute
   * @param state
   */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const redirectUrl = childRoute.queryParams['continue'];
    const stateObj = childRoute.queryParams['state'];
    return this._check(redirectUrl, stateObj);
  }

  /**
   * Can load
   *
   * @param route
   * @param segments
   */
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._check();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @private
   */
  private _check(redirectUrl?: string, state?: string): Observable<boolean> {
    // console.log('this._authService', this._authService);
    // Check the authentication status
    return this._authService.check().pipe(
      switchMap((authenticated) => {
        // If the user is authenticated...
        if (!authenticated) {
          console.log('authenticated --> ', authenticated);
          // Prevent the access
          return of(false).pipe(
            tap(() => {
              // Redirect to the root
              this._router.navigate(['sign-in']);
            })
          );
        }
        if (redirectUrl) {
          return of(false).pipe(
            tap(() => {
              this._authService.signOut();
              const url = new URL(
                `${redirectUrl}` + (state ? `?state=${state}` : '')
              );
              window.location.href = url.href;
            })
          );
        }

        // Allow the access
        return of(true);
      })
    );
  }
}
