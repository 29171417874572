/* eslint-disable no-useless-escape */
import {
  createCodeAuthorizationParams,
  getTokenFromCode,
  pickAvailabelAuthorizeParams,
} from './authorization-code-flow';
import { b64DecodeUnicode } from './base64';
import { IRedocOauth2Config, RedocOauth2Config } from './config';
import { DEFAULT_AUTHORIZE_TIMEOUT_IN_SECONDS } from './constants';
import { getDateInstance, getNow } from './date-time';
import { ErrorBase } from './error.model';
import {
  RedocOAuthErrorEvent,
  RedocOAuthEvent,
  RedocOAuthInfoEvent,
  RedocOAuthSuccessEvent,
} from './event';
import { getJSON } from './http';
import { RedocOAuthLogger } from './logger';
import { LoginOptions } from './login-option';
import { createNonce } from './material/nonce';
import { createUrl } from './material/url';
import { TokenKeyStoreSet, createTokenKeyStoreSet } from './models';
import { AuthorizationParams } from './models/authorize.model';
import { RedocDiscoveryDoc } from './models/discovery-doc.model';
import { ParsedIdToken } from './parsed-id-token';
import { PopupConfigOptions } from './popup.model';
import { RedocOAuthStorage } from './storage';
import { TokenResponse, TokenResponseKey } from './token-response';
import { getHashFragmentParams, parseQueryString } from './url';
import { openPopup, runPopup } from './utils';
import { createRedocOauthValidator, RedocOauthValidation } from './validator';

export interface RedocOauth2ClientConnectOptionCallback {
  onEvent: (e: RedocOAuthEvent) => void;
  onConfigChanged: () => void;
}
export class RedocOauth2ClientConnect {
  config!: RedocOauth2Config;
  private _storage!: RedocOAuthStorage;
  silentRefreshSubject: string | null = null;
  protected inImplicitFlow = false;
  discoveryDocument?: RedocDiscoveryDoc;
  protected discoveryDocumentLoaded = false;
  protected saveNoncesInLocalStorage = false;
  protected validation!:RedocOauthValidation;

  // keystore set
  idTokenSet!: TokenKeyStoreSet;
  accessTokenSet!: TokenKeyStoreSet;
  refreshTokenSet!: TokenKeyStoreSet;
  constructor(
    storage: RedocOAuthStorage,
    public document: Document,
    public logger: RedocOAuthLogger,
    public optionCallBack: Partial<RedocOauth2ClientConnectOptionCallback>,
    tokenSetConfig?: { prefix?: string }
  ) {
    this.validation = createRedocOauthValidator({},logger)
    this.idTokenSet = createTokenKeyStoreSet(
      'id_token',
      tokenSetConfig?.prefix
    );
    this.accessTokenSet = createTokenKeyStoreSet(
      'access_token',
      tokenSetConfig?.prefix
    );
    this.refreshTokenSet = createTokenKeyStoreSet(
      'refresh_token',
      tokenSetConfig?.prefix
    );
    try {
      // console.log('storage', storage);
      if (storage) {
        this.setStorage(storage);
      } else if (typeof sessionStorage !== 'undefined') {
        this.setStorage(sessionStorage);
      }
    } catch (e) {
      console.error(
        'No OAuthStorage provided and cannot access default (sessionStorage).' +
          'Consider providing a custom OAuthStorage implementation in your module.',
        e
      );
    }
    if (this.checkLocalStorageAccessable()) {
      const ua = window?.navigator?.userAgent;
      const msie = ua?.includes('MSIE ') || ua?.includes('Trident');

      if (msie) {
        this.saveNoncesInLocalStorage = true;
      }
    }
  }
  /**
   * The received (passed around) state, when logging
   * in with implicit flow.
   */
  public state = '';
  configure(config: Partial<IRedocOauth2Config>): void {
    // For the sake of downward compatibility with
    // original configuration API
    // Object.assign(this, new RedocOauth2Config(), config);

    this.config = new RedocOauth2Config(Object.assign({}, config));
    this.validation.updateValueAndValidiy(this.config);
    this.configChanged();
  }
  public setStorage(storage: RedocOAuthStorage): void {
    this._storage = storage;
    // this.configChanged();
  }
  configChanged(): void {
    if (this.optionCallBack.onConfigChanged) {
      this.optionCallBack.onConfigChanged();
    }
    // this.setupRefreshTimer();
  }

  // abstract setupRefreshTimer(): void;
  // abstract fetchToken(
  //   params: Record<string, string>,
  //   headers: Record<string, string>,
  //   options: LoginOptions
  // ): Promise<TokenResponse>;
  /**
   * Checkes, whether there is a valid access_token.
   */
  public hasValidAccessToken(): boolean {
    if (this.getAccessToken()) {
      const expiresAt = this._storage.getItem(this.accessTokenSet.expiresAt);
      const now = getDateInstance();
      if (
        expiresAt &&
        parseInt(expiresAt, 10) - this.config.decreaseExpirationBySec <
          now.getTime() - this.getClockSkewInMsec()
      ) {
        return false;
      }

      return true;
    }

    return false;
  }
  /**
   * Checks whether there is a valid id_token.
   */
  public hasValidIdToken(): boolean {
    if (this.getIdToken()) {
      const expiresAt = this._storage.getItem(this.idTokenSet.expiresAt);
      const now = getDateInstance();
      if (
        expiresAt &&
        parseInt(expiresAt, 10) - this.config.decreaseExpirationBySec <
          now.getTime() - this.getClockSkewInMsec()
      ) {
        return false;
      }

      return true;
    }

    return false;
  }
  private getClockSkewInMsec(defaultSkewMsc = 600_000) {
    if (!this.config.clockSkewInSec && this.config.clockSkewInSec !== 0) {
      return defaultSkewMsc;
    }
    return this.config.clockSkewInSec * 1000;
  }
  /**
   * Returns the current access_token.
   */
  public getAccessToken(): string | null {
    return this._storage
      ? this._storage.getItem(this.accessTokenSet.name)
      : null;
  }
  /**
   * Returns the current refresh_token.
   */
  public getRefreshToken(): string | null {
    return this._storage
      ? this._storage.getItem(this.refreshTokenSet.name)
      : null;
  }
  /**
   * Returns the current id_token.
   */
  public getIdToken(): string | null {
    return this._storage ? this._storage.getItem(this.idTokenSet.name) : null;
  }
  /**
   * Returns the expiration date of the id_token
   * as milliseconds since 1970.
   */
  public getIdTokenExpiration(): number | null {
    const expiresAt = this._storage.getItem(this.idTokenSet.expiresAt);
    if (!expiresAt) {
      return null;
    }

    return parseInt(expiresAt, 10);
  }
  /**
   * Convenience method that first calls `loadDiscoveryDocument(...)` and
   * directly chains using the `then(...)` part of the promise to call
   * the `tryLogin(...)` method.
   *
   * @param options LoginOptions to pass through to `tryLogin(...)`
   */
  public loadDiscoveryDocumentAndTryLogin(
    options?: LoginOptions
  ): Promise<boolean> {
    return this.loadDiscoveryDocument().then(() => {
      return this.tryLogin(options);
    });
  }

  /**
   * Loads the discovery document to configure most
   * properties of this service. The url of the discovery
   * document is infered from the issuer's url according
   * to the OpenId Connect spec. To use another url you
   * can pass it to to optional parameter fullUrl.
   *
   * @param fullUrl
   */
  public async loadDiscoveryDocument(
    fullUrl?: string
  ): Promise<RedocOAuthSuccessEvent> {
    return new Promise((resolve, reject) => {
      if (this.discoveryDocumentLoaded) {
        const event = new RedocOAuthSuccessEvent('discovery_document_loaded', {
          discoveryDocument: this.discoveryDocument as RedocDiscoveryDoc,
        });
        resolve(event);
        return;
      }
      if (!fullUrl) {
        fullUrl = this.config.issuer || '';
        if (!fullUrl.endsWith('/')) {
          fullUrl += '/';
        }
        fullUrl += '.well-known/redocid-configuration';
      }

      if (!this.validation.validateUrlForHttps(fullUrl)) {
        reject(
          "issuer  must use HTTPS (with TLS), or config value for property 'requireHttps' must be set to 'false' and allow HTTP (without TLS)."
        );
        return;
      }
      getJSON<RedocDiscoveryDoc>(
        fullUrl,
        undefined,
        'default',
        this.config.scope,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
        .then((doc) => {
          const validateResult = this.validation.validateDiscoveryDocument(doc)
          if (!validateResult.valid) {
            this.pushEvent(
              new RedocOAuthErrorEvent(
                'discovery_document_validation_error',
                validateResult
              )
            );
            reject(validateResult.msg);
            return;
          }
          console.log('docovery -->', doc);
          this.config.authorizeUrl = doc.authorization_endpoint;
          this.config.logoutUrl =
            doc.end_session_endpoint || this.config.logoutUrl;
          // this.config.grantTypesSupported = doc.grant_types_supported;
          this.config.issuer = doc.issuer;
          this.config.tokenEndpoint = doc.token_endpoint;
          // this.config.userinfoEndpoint =
          //   doc.userinfo_endpoint || this.config.userinfoEndpoint;
          // this.jwksUri = doc.jwks_uri;
          // this.config.sessionCheckIFrameUrl =
          //   doc.check_session_iframe || this.config.sessionCheckIFrameUrl;

          this.discoveryDocumentLoaded = true;
          this.discoveryDocument = doc;
          // this.discoveryDocumentLoadedSubject.next(doc);
          // this.revocationEndpoint =
          //   doc.revocation_endpoint || this.revocationEndpoint;

          // if (this.config.sessionChecksEnabled) {
          //   this.restartSessionChecksIfStillLoggedIn();
          // }

          // this.loadJwks()
          //   .then((jwks) => {
          //     const result: object = {
          //       discoveryDocument: doc,
          //       jwks: jwks,
          //     };

          //     const event = new RedocOAuthSuccessEvent(
          //       'discovery_document_loaded',
          //       result
          //     );
          //     this.pushEvent(event);
          //     resolve(event);
          //     return;
          //   })
          //   .catch((err:any) => {
          //     this.pushEvent(
          //       new RedocOAuthErrorEvent('discovery_document_load_error', err)
          //     );
          //     reject(err);
          //     return;
          //   });
          const event = new RedocOAuthSuccessEvent(
            'discovery_document_loaded',
            {
              discoveryDocument: doc,
            }
          );
          this.pushEvent(event);
          resolve(event);
          return;
        })
        .catch((err) => {
          this.logger.error('error loading discovery document', err);

          const event = new RedocOAuthErrorEvent(
            'discovery_document_load_error',
            err
          );

          this.pushEvent(event);
          reject(err);
        });
      // this.http.get<OidcDiscoveryDoc>(fullUrl).subscribe(
      //   (doc) => {
      //     if (!this.validateDiscoveryDocument(doc)) {
      //       this.eventsSubject.next(
      //         new OAuthErrorEvent('discovery_document_validation_error', null)
      //       );
      //       reject('discovery_document_validation_error');
      //       return;
      //     }

      //     this.loginUrl = doc.authorization_endpoint;
      //     this.logoutUrl = doc.end_session_endpoint || this.logoutUrl;
      //     this.grantTypesSupported = doc.grant_types_supported;
      //     this.issuer = doc.issuer;
      //     this.tokenEndpoint = doc.token_endpoint;
      //     this.userinfoEndpoint =
      //       doc.userinfo_endpoint || this.userinfoEndpoint;
      //     this.jwksUri = doc.jwks_uri;
      //     this.sessionCheckIFrameUrl =
      //       doc.check_session_iframe || this.sessionCheckIFrameUrl;

      //     this.discoveryDocumentLoaded = true;
      //     this.discoveryDocumentLoadedSubject.next(doc);
      //     this.revocationEndpoint =
      //       doc.revocation_endpoint || this.revocationEndpoint;

      //     if (this.sessionChecksEnabled) {
      //       this.restartSessionChecksIfStillLoggedIn();
      //     }

      //     this.loadJwks()
      //       .then((jwks) => {
      //         const result: object = {
      //           discoveryDocument: doc,
      //           jwks: jwks,
      //         };

      //         const event = new OAuthSuccessEvent(
      //           'discovery_document_loaded',
      //           result
      //         );
      //         this.eventsSubject.next(event);
      //         resolve(event);
      //         return;
      //       })
      //       .catch((err) => {
      //         this.eventsSubject.next(
      //           new OAuthErrorEvent('discovery_document_load_error', err)
      //         );
      //         reject(err);
      //         return;
      //       });
      //   },
      //   (err) => {
      //     this.logger.error('error loading discovery document', err);
      //     this.eventsSubject.next(
      //       new OAuthErrorEvent('discovery_document_load_error', err)
      //     );
      //     reject(err);
      //   }
      // );
      // const event = new RedocOAuthSuccessEvent('discovery_document_loaded', {});
      // this.pushEvent(event);
      // resolve(event);
    });
  }
  /**
   * Returns the expiration date of the access_token
   * as milliseconds since 1970.
   */
  public getAccessTokenExpiration(): number | null {
    const expiresAt = this._storage.getItem(this.accessTokenSet.expiresAt);
    if (!expiresAt) {
      return null;
    }
    return parseInt(expiresAt, 10);
  }
  getIdTokenStoredAt(): number | null {
    const storedAt = this._storage.getItem(this.idTokenSet.storedAt);
    return storedAt ? parseInt(storedAt, 10) : null;
  }

  initSessionCheck(): void {
    // if (!this.canPerformSessionCheck()) {
    //   return;
    // }
    // const existingIframe = this.document.getElementById(
    //   this.config.sessionCheckIFrameName
    // );
    // if (existingIframe) {
    //   this.document.body.removeChild(existingIframe);
    // }
    // const iframe = this.document.createElement('iframe');
    // iframe.id = this.config.sessionCheckIFrameName;
    // this.setupSessionCheckEventListener();
    // const url = this.config.sessionCheckIFrameUrl;
    // iframe.setAttribute('src', url);
    // iframe.style.display = 'none';
    // this.document.body.appendChild(iframe);
    // this.startSessionCheckTimer();
  }

  /**
   * Stops timers for automatic refresh.
   * To restart it, call setupAutomaticSilentRefresh again.
   */

  calcTimeout(storedAt: number | null, expiration: number | null): number {
    const now = getNow();
    storedAt = storedAt || now;
    expiration = expiration || now;
    const delta =
      (expiration - storedAt) * this.config.timeoutFactor - (now - storedAt);
    const duration = Math.max(0, delta);
    const maxTimeoutValue = 2_147_483_647;
    return duration > maxTimeoutValue ? maxTimeoutValue : duration;
  }
  calcTokenTimeout(
    tokenType: 'access_token' | 'fresh_token' | 'id_token'
  ): number {
    if (tokenType === 'access_token') {
      const expiration = this.getAccessTokenExpiration();
      const storedAt = this.getAccessTokenStoredAt();
      return this.calcTimeout(expiration, storedAt);
    }
    if (tokenType === 'id_token') {
      const expiration = this.getIdTokenExpiration();
      const storedAt = this.getIdTokenStoredAt();
      return this.calcTimeout(storedAt, expiration);
    }
    throw new Error(
      `can't calculate token timeout with tokenType is ${tokenType}`
    );
  }

  /**
   * Delegates to tryLoginImplicitFlow for the sake of competability
   * @param options Optional options.
   */
  public tryLogin(options?: LoginOptions): Promise<boolean> {
    console.log('tryLogin', this.config.responseType);
    if (this.config.responseType === 'code') {
      return this.tryLoginCodeFlow(options).then((_) => true);
    } else {
      return this.tryLoginImplicitFlow(options);
    }
  }
  public async tryLoginCodeFlow(options?: LoginOptions): Promise<void> {
    console.log('tryLoginCodeFlow');
    options = options || {};

    const querySource = options.customHashFragment
      ? options.customHashFragment.substring(1)
      : window.location.search;

    const parts = this.getCodePartsFromUrl(querySource);
    const code = parts['code'];
    const state = parts['state'];

    const sessionState = parts['session_state'];
    if (!options.preventClearHashAfterLogin) {
      const href =
        location.origin +
        location.pathname +
        location.search
          .replace(/code=[^&\$]*/, '')
          .replace(/scope=[^&\$]*/, '')
          .replace(/state=[^&\$]*/, '')
          .replace(/session_state=[^&\$]*/, '')
          .replace(/^\?&/, '?')
          .replace(/&$/, '')
          .replace(/^\?$/, '')
          .replace(/&+/g, '&')
          .replace(/\?&/, '?')
          .replace(/\?$/, '') +
        location.hash;

      history.replaceState(null, window.name, href);
    }
    const [nonceInState, userState] = this.parseState(state);
    this.state = userState;

    if (parts['error']) {
      this.debug('error trying to login');
      this.handleLoginError(options, parts);
      const err = new RedocOAuthErrorEvent('code_error', {}, parts);
      this.pushEvent(err);
      return Promise.reject(err);
    }
    console.log('options.disableNonceCheck', options);
    if (!options.disableNonceCheck) {
      if (!nonceInState) {
        this.saveRequestedRoute();
        return Promise.resolve();
      }

      if (!options.disableOAuth2StateCheck) {
        const success = this.validateNonce(nonceInState);
        if (!success) {
          const event = new RedocOAuthErrorEvent(
            'invalid_nonce_in_state',
            null
          );
          this.pushEvent(event);
          return Promise.reject(event);
        }
      }
    }
    console.log('code', code);
    let codeVerifier;
    if (
      this.saveNoncesInLocalStorage &&
      typeof window['localStorage'] !== 'undefined'
    ) {
      codeVerifier = localStorage.getItem('code_verifier');
    } else {
      codeVerifier = this._storage.getItem('code_verifier');
    }
    if (!codeVerifier) {
      const event = new RedocOAuthErrorEvent(
        'invalid_code_verifier_in_state',
        null
      );
      this.pushEvent(event);
      return Promise.reject(event);
    }
    this.storeSessionState(sessionState);
    if (code) {
      await this.getAndSaveTokenFromCode({ code, codeVerifier }, options);
      this.restoreRequestedRoute();
      return Promise.resolve();
    } else {
      return Promise.resolve();
    }
  }
  /**
   * Checks whether there are tokens in the hash fragment
   * as a result of the implicit flow. These tokens are
   * parsed, validated and used to sign the user in to the
   * current client.
   *
   * @param options Optional options.
   */
  public tryLoginImplicitFlow(options: LoginOptions = {}): Promise<boolean> {
    // options =Object.assign({},options)  as LoginOptions;

    let parts: Record<string, any>;
    if (options.customHashFragment) {
      parts = getHashFragmentParams(options.customHashFragment);
    } else {
      parts = getHashFragmentParams();
    }
    console.log('tryLoginImplicitFlow');
    this.debug('parsed url', parts);
    const state = parts['state'];

    const [nonceInState, userState] = this.parseState(state);
    this.state = userState;

    if (parts['error']) {
      this.debug('error trying to login');
      this.handleLoginError(options, parts);
      const err = new RedocOAuthErrorEvent('token_error', {}, parts);
      this.pushEvent(err);
      return Promise.reject(err);
    }

    const accessToken = parts[this.accessTokenSet.name];
    const idToken = parts[this.idTokenSet.name];
    const sessionState = parts['session_state'];
    const grantedScopes = parts['scope'];
    // if (!this.requestAccessToken && !this.oidc) {
    //   return Promise.reject(
    //     'Either requestAccessToken or oidc (or both) must be true.'
    //   );
    // }

    this.debug(
      'processIdToken result',
      this.config.requestAccessToken,
      !accessToken
    );
    if (this.config.requestAccessToken && !accessToken) {
      return Promise.resolve(false);
    }
    if (
      this.config.requestAccessToken &&
      !options.disableOAuth2StateCheck &&
      !state
    ) {
      return Promise.resolve(false);
    }
    // if (this.oidc && !idToken) {
    //   return Promise.resolve(false);
    // }
    // if (this.config.sessionChecksEnabled && !sessionState) {
    //   this.logger.warn(
    //     'session checks (Session Status Change Notification) ' +
    //       'were activated in the configuration but the id_token ' +
    //       'does not contain a session_state claim'
    //   );
    // }
    if (this.config.requestAccessToken && !options.disableNonceCheck) {
      const success = this.validateNonce(nonceInState);

      if (!success) {
        const event = new RedocOAuthErrorEvent('invalid_nonce_in_state', null);
        this.pushEvent(event);
        return Promise.reject(event);
      }
    }

    if (this.config.requestAccessToken) {
      this.storeAccessTokenResponse(
        accessToken,
        null,
        parts['expires_in'] ||
          this.config.fallbackAccessTokenExpirationTimeInSec,
        grantedScopes
      );
    }
    // if (!this.oidc) {
    //   this.eventsSubject.next(new OAuthSuccessEvent('token_received'));
    //   if (this.clearHashAfterLogin && !options.preventClearHashAfterLogin) {
    //     this.clearLocationHash();
    //   }

    //   this.callOnTokenReceivedIfExists(options);
    //   return Promise.resolve(true);
    // }
    return this.processIdToken(idToken, accessToken, options.disableNonceCheck)
      .then((result) => {
        this.debug('processIdToken result', result);
        // if (options.validationHandler) {
        //   return options
        //     .validationHandler({
        //       accessToken: accessToken,
        //       idClaims: result.idTokenClaims,
        //       idToken: result.idToken,
        //       state: state,
        //     })
        //     .then((_) => result);
        // }
        return result;
      })
      .then((result) => {
        this.storeIdToken(result);
        this.storeSessionState(sessionState);
        // if (this.clearHashAfterLogin && !options.preventClearHashAfterLogin) {
        //   this.clearLocationHash();
        // }
        this.pushEvent(new RedocOAuthSuccessEvent('token_received'));
        this.callOnTokenReceivedIfExists(options);
        this.inImplicitFlow = false;
        return true;
      })
      .catch((reason) => {
        this.pushEvent(
          new RedocOAuthErrorEvent('token_validation_error', reason)
        );
        this.logger.error('Error validating tokens');
        this.logger.error(reason);
        return Promise.reject(reason);
      });
  }

  protected validateNonce(nonceInState: string): boolean {
    let savedNonce;

    if (
      this.saveNoncesInLocalStorage &&
      typeof window['localStorage'] !== 'undefined'
    ) {
      savedNonce = localStorage.getItem('nonce');
    } else {
      savedNonce = this._storage.getItem('nonce');
    }

    if (savedNonce !== nonceInState) {
      const err = 'Validating access_token failed, wrong state/nonce.';
      console.error(err, savedNonce, nonceInState);
      return false;
    }
    return true;
  }
  handleLoginError(options: LoginOptions, parts: object): void {
    if (options.onLoginError) {
      options.onLoginError(parts);
    }
    if (
      this.config.clearHashAfterLogin &&
      !options.preventClearHashAfterLogin
    ) {
      this.clearLocationHash();
    }
  }
  pushEvent(event: RedocOAuthEvent): void {
    if (this.optionCallBack.onEvent) {
      this.optionCallBack.onEvent(event);
    }
  }
  private parseState(state: string): [string, string] {
    let nonce = state;
    let userState = '';

    if (state) {
      const idx = state.indexOf(this.config.nonceStateSeparator);
      if (idx > -1) {
        nonce = state.substring(0, idx);
        userState = state.substring(
          idx + this.config.nonceStateSeparator.length
        );
      }
    }
    return [nonce, userState];
  }
  private getCodePartsFromUrl(queryString: string): Record<string, string> {
    if (!queryString || queryString.length === 0) {
      return getHashFragmentParams();
    }

    // normalize query string
    if (queryString.charAt(0) === '?') {
      queryString = queryString.substring(1);
    }

    return parseQueryString(queryString);
  }
  private saveRequestedRoute() {
    if (this.config.preserveRequestedRoute) {
      this._storage.setItem(
        'requested_route',
        window.location.pathname + window.location.search
      );
    }
  }
  public processIdToken(
    idToken: string,
    accessToken: string,
    skipNonceCheck = false
  ): Promise<ParsedIdToken> {
    const tokenParts = idToken.split('.');
    const headerBase64 = this.padBase64(tokenParts[0]);
    console.log('headerBase64', headerBase64);
    const headerJson = b64DecodeUnicode(headerBase64);
    const header = JSON.parse(headerJson);
    const claimsBase64 = this.padBase64(tokenParts[1]);
    const claimsJson = b64DecodeUnicode(claimsBase64);
    const claims = JSON.parse(claimsJson);

    let savedNonce;
    if (
      this.saveNoncesInLocalStorage &&
      typeof window['localStorage'] !== 'undefined'
    ) {
      savedNonce = localStorage.getItem('nonce');
    } else {
      savedNonce = this._storage.getItem('nonce');
    }

    if (Array.isArray(claims.aud)) {
      if (claims.aud.every((v: string) => v !== this.config.clientId)) {
        const err = 'Wrong audience: ' + claims.aud.join(',');
        this.logger.warn(err);
        return Promise.reject(err);
      }
    } else {
      if (claims.aud !== this.config.clientId) {
        const err = 'Wrong audience: ' + claims.aud;
        this.logger.warn(err);
        return Promise.reject(err);
      }
    }

    if (!claims.sub) {
      const err = 'No sub claim in id_token';
      this.logger.warn(err);
      return Promise.reject(err);
    }

    /* For now, we only check whether the sub against
     * silentRefreshSubject when sessionChecksEnabled is on
     * We will reconsider in a later version to do this
     * in every other case too.
     */
    if (
      this.config.sessionChecksEnabled &&
      this.silentRefreshSubject &&
      this.silentRefreshSubject !== claims['sub']
    ) {
      const err =
        'After refreshing, we got an id_token for another user (sub). ' +
        `received sub: ${claims['sub']}`;

      this.logger.warn(err);
      return Promise.reject(err);
    }

    if (!claims.iat) {
      const err = 'No iat claim in id_token';
      this.logger.warn(err);
      return Promise.reject(err);
    }

    if (!this.config.skipIssuerCheck && claims.iss !== this.config.issuer) {
      const err = 'Wrong issuer: ' + claims.iss;
      this.logger.warn(err);
      return Promise.reject(err);
    }

    if (!skipNonceCheck && claims.nonce !== savedNonce) {
      const err = 'Wrong nonce: ' + claims.nonce;
      this.logger.warn(err);
      return Promise.reject(err);
    }
    // at_hash is not applicable to authorization code flow
    // addressing https://github.com/manfredsteyer/angular-oauth2-oidc/issues/661
    // i.e. Based on spec the at_hash check is only true for implicit code flow on Ping Federate
    // https://www.pingidentity.com/developer/en/resources/openid-connect-developers-guide.html
    if (
      this.config.responseType === 'code' ||
      this.config.responseType === 'id_token'
    ) {
      this.config.disableAtHashCheck = true;
    }
    if (
      !this.config.disableAtHashCheck &&
      this.config.requestAccessToken &&
      !claims['at_hash']
    ) {
      const err = 'An at_hash is needed!';
      this.logger.warn(err);
      return Promise.reject(err);
    }

    const now = getNow();
    const issuedAtMSec = claims.iat * 1000;
    const expiresAtMSec = claims.exp * 1000;
    const clockSkewInMSec = this.getClockSkewInMsec(); // (this.getClockSkewInMsec() || 600) * 1000;

    if (
      issuedAtMSec - clockSkewInMSec >= now ||
      expiresAtMSec + clockSkewInMSec - this.config.decreaseExpirationBySec <=
        now
    ) {
      const err = 'Token has expired';
      this.logger.error(err);
      this.logger.error({
        now: now,
        issuedAtMSec: issuedAtMSec,
        expiresAtMSec: expiresAtMSec,
      });
      return Promise.reject(err);
    }
    const result: ParsedIdToken = {
      idToken: idToken,
      idTokenClaims: claims,
      idTokenClaimsJson: claimsJson,
      idTokenHeader: header,
      idTokenHeaderJson: headerJson,
      idTokenExpiresAt: expiresAtMSec,
    };
    return Promise.resolve(result);
  }
  customSessionChecksEnabled(): boolean {
    return false;
  }
  padBase64(base64data: string): string {
    while (base64data.length % 4 !== 0) {
      base64data += '=';
    }
    return base64data;
  }
  protected storeAccessTokenResponse(
    accessToken: string,
    refreshToken: string | null | undefined,
    expiresIn: number | null | undefined,
    grantedScopes: string | null | undefined,
    customParameters?: Map<string, string>
  ): void {
    this._storage.setItem(this.accessTokenSet.name, accessToken);
    if (grantedScopes && !Array.isArray(grantedScopes)) {
      this._storage.setItem(
        'granted_scopes',
        JSON.stringify(grantedScopes.split(' '))
      );
    } else if (grantedScopes && Array.isArray(grantedScopes)) {
      this._storage.setItem('granted_scopes', JSON.stringify(grantedScopes));
    }

    this._storage.setItem(this.accessTokenSet.storedAt, '' + getNow());
    if (expiresIn) {
      const expiresInMilliSeconds = expiresIn * 1000;
      const now = getDateInstance();
      const expiresAt = now.getTime() + expiresInMilliSeconds;
      this._storage.setItem(this.accessTokenSet.expiresAt, '' + expiresAt);
    }

    if (refreshToken) {
      this._storage.setItem(this.refreshTokenSet.name, refreshToken);
    }
    if (customParameters) {
      customParameters.forEach((value: string, key: string) => {
        this._storage.setItem(key, value);
      });
    }
  }
  protected storeIdToken(idToken: ParsedIdToken): void {
    console.log('storeIdToken', this._storage);
    this._storage.setItem(this.idTokenSet.name, idToken.idToken);
    this._storage.setItem(this.idTokenSet.claimsObj, idToken.idTokenClaimsJson);
    this._storage.setItem(
      this.idTokenSet.expiresAt,
      '' + idToken.idTokenExpiresAt
    );
    this._storage.setItem(this.idTokenSet.storedAt, '' + getNow());
  }
  protected storeSessionState(sessionState: string): void {
    this._storage.setItem('session_state', sessionState);
  }
  protected getAccessTokenStoredAt(): number | null {
    const accessTokenStoredAt = this._storage.getItem(
      this.accessTokenSet.storedAt
    );
    return accessTokenStoredAt ? parseInt(accessTokenStoredAt, 10) : null;
  }
  protected getSessionState(): string | null {
    return this._storage.getItem('session_state');
  }
  /**
   * Reset current implicit flow
   *
   * @description This method allows resetting the current implict flow in order to be initialized again.
   */
  public resetImplicitFlow(): void {
    this.inImplicitFlow = false;
  }

  protected callOnTokenReceivedIfExists(options: LoginOptions): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    if (options.onTokenReceived) {
      const tokenParams = {
        idClaims: that.getIdentityClaims(),
        idToken: that.getIdToken() as string,
        accessToken: that.getAccessToken() as string,
        state: that.state,
      };
      options.onTokenReceived(tokenParams);
    }
  }
  /**
   * Returns the received claims about the user.
   */
  public getIdentityClaims(): Record<string, any> {
    const claims = this._storage.getItem(this.idTokenSet.claimsObj);
    if (!claims) {
      return {};
    }
    return JSON.parse(claims);
  }
  /**
   * Get token using an intermediate code. Works for the Authorization Code flow.
   */
  private getAndSaveTokenFromCode(
    payload: { code: string; codeVerifier: string },
    options: LoginOptions
  ): Promise<TokenResponse> {
    // let params = new HttpParams({ encoder: new WebHttpUrlEncodingCodec() })
    //   .set('grant_type', 'authorization_code')
    //   .set('code', code)
    //   .set('redirect_uri', options.customRedirectUri || this.redirectUri);
    // const params: Record<string, any> = {
    //   grant_type: 'authorization_code',
    //   code,
    //   redirect_uri: options.customRedirectUri || this.config.redirectUri,
    //   ...this.config.customQueryParams,
    // };
    // const headers: Record<string, any> = {
    //   'Content-Type': 'application/x-www-form-urlencoded',
    // };
    // if (!this.disablePKCE) {
    //   let PKCEVerifier;

    //   if (
    //     this.saveNoncesInLocalStorage &&
    //     typeof window['localStorage'] !== 'undefined'
    //   ) {
    //     PKCEVerifier = localStorage.getItem('PKCE_verifier');
    //   } else {
    //     PKCEVerifier = this._storage.getItem('PKCE_verifier');
    //   }

    //   if (!PKCEVerifier) {
    //     console.warn('No PKCE verifier found in oauth storage!');
    //   } else {
    //     params = params.set('code_verifier', PKCEVerifier);
    //   }
    // }
    return new Promise((resolve, reject) => {
      // this.fetchToken(params, headers, options)
      getTokenFromCode(
        payload,
        this.config,
        options.customRedirectUri || this.config.redirectUri
      ).then((tokenResponse: TokenResponse) => {
        this.debug('refresh tokenResponse', tokenResponse);
        this.storeAccessTokenResponse(
          tokenResponse.access_token,
          tokenResponse.refresh_token,
          tokenResponse.expires_in ||
            this.config.fallbackAccessTokenExpirationTimeInSec,
          tokenResponse.scope,
          this.extractRecognizedCustomParameters(tokenResponse)
        );
        if (tokenResponse.id_token) {
          this.processIdToken(
            tokenResponse.id_token,
            tokenResponse.access_token,
            options.disableNonceCheck
          )
            .then((result) => {
              this.storeIdToken(result);

              this.pushEvent(new RedocOAuthSuccessEvent('token_received'));
              this.pushEvent(new RedocOAuthSuccessEvent('token_refreshed'));

              resolve(tokenResponse);
            })
            .catch((reason) => {
              this.pushEvent(
                new RedocOAuthErrorEvent('token_validation_error', reason)
              );
              console.error('Error validating tokens');
              console.error(reason);

              reject(reason);
            });
        } else {
          this.pushEvent(new RedocOAuthSuccessEvent('token_received'));
          this.pushEvent(new RedocOAuthSuccessEvent('token_refreshed'));
          resolve(tokenResponse);
        }
      });
    });
  }
  private checkLocalStorageAccessable() {
    if (typeof window === 'undefined') return false;

    const test = 'test';
    try {
      if (typeof window['localStorage'] === 'undefined') return false;

      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  }
  public initLoginFlowInPopup(options?: {
    height?: number;
    width?: number;
    windowRef?: Window;
  }) {
    options = options || {};
    this.loginWithPopup();
  }
  public initForcedLoginFlowInPopup(token: string): void {
    this.loginWithPopup({
      authorizationParams: {
        with_token: token,
      },
    });
  }
  private extractRecognizedCustomParameters(
    tokenResponse: TokenResponse
  ): Map<string, string> {
    const foundParameters: Map<string, string> = new Map<string, string>();
    if (!this.config.customTokenParameters) {
      return foundParameters;
    }
    this.config.customTokenParameters.forEach(
      (recognizedParameter: TokenResponseKey) => {
        if (tokenResponse[recognizedParameter]) {
          foundParameters.set(
            recognizedParameter,
            JSON.stringify(tokenResponse[recognizedParameter])
          );
        }
      }
    );
    return foundParameters;
  }
  /**
   * Clear location.hash if it's present
   */
  private clearLocationHash() {
    // Checking for empty hash is necessary for Firefox
    // as setting an empty hash to an empty string adds # to the URL
    if (location.hash != '') {
      location.hash = '';
    }
  }
  private restoreRequestedRoute() {
    const requestedRoute = this._storage.getItem('requested_route');
    if (requestedRoute) {
      history.replaceState(null, '', window.location.origin + requestedRoute);
    }
  }

  protected async initCodeFlowInternal(
    additionalState = '',
    params: Partial<AuthorizationParams> = {}
  ): Promise<void> {
    if (!this.validation.validateUrlForHttps(this.config.loginUrl)) {
      throw new Error(
        "loginUrl  must use HTTPS (with TLS), or config value for property 'requireHttps' must be set to 'false' and allow HTTP (without TLS)."
      );
    }

    let addParams: Partial<AuthorizationParams> = {};
    let loginHint;
    if (typeof params === 'string') {
      loginHint = params;
    } else if (typeof params === 'object') {
      addParams = params;
    }

    const querySearch = await createCodeAuthorizationParams(
      this.config,
      { ...addParams, response_mode: 'web_message' },
      () => this.createAndSaveNonce(),
      additionalState
    );
    const url = createUrl(
      this.config.authorizeUrl,
      pickAvailabelAuthorizeParams(querySearch)
    );
    if (
      this.saveNoncesInLocalStorage &&
      typeof window['localStorage'] !== 'undefined'
    ) {
      localStorage.setItem('code_verifier', querySearch.code_verifier);
    } else {
      this._storage.setItem('code_verifier', querySearch.code_verifier);
    }
    console.log('url', url);
    this.openUri(url);
  }
  async initImplicitFlowInternal(
    additionalState = '',
    params: string | object = ''
  ): Promise<void> {
    if (this.inImplicitFlow) {
      return;
    }

    this.inImplicitFlow = true;

    if (!this.validation.validateUrlForHttps(this.config.loginUrl)) {
      throw new Error(
        "loginUrl  must use HTTPS (with TLS), or config value for property 'requireHttps' must be set to 'false' and allow HTTP (without TLS)."
      );
    }

    let addParams: object = {};
    let loginHint = '';

    if (typeof params === 'string') {
      loginHint = params;
    } else if (typeof params === 'object') {
      addParams = params;
    }
    const querySearch = await createCodeAuthorizationParams(
      this.config,
      { response_mode: 'web_message' },
      () => this.createAndSaveNonce(),
      additionalState
    );
    const url = createUrl(
      this.config.authorizeUrl,
      pickAvailabelAuthorizeParams(querySearch)
    );
    this.openUri(url);
  }
  public initLoginFlow(
    additionalState = '',
    params: Partial<AuthorizationParams> = {}
  ): void {
    console.log('initLoginFlow');
    if (this.config.responseType === 'code') {
      return this.initCodeFlow(additionalState, params);
    } else {
      return this.initImplicitFlow(additionalState, params);
    }
  }
  /**
   * Starts the authorization code flow and redirects to user to
   * the auth servers login url.
   */
  public initCodeFlow(additionalState = '', params = {}): void {
    console.log('initCodeFlow', this.config);
    if (this.config.loginUrl !== '') {
      this.initCodeFlowInternal(additionalState, params);
    } else {
      this.loadDiscoveryDocument().then(() => {
        this.initCodeFlowInternal(additionalState, params);
      });
    }
  }
  public initImplicitFlow(
    additionalState = '',
    params: string | object = ''
  ): void {
    if (this.config.loginUrl !== '') {
      this.initImplicitFlowInternal(additionalState, params);
    } else {
      this.loadDiscoveryDocument().then(() => {
        this.initImplicitFlowInternal(additionalState, params);
      });
    }
  }

  public async loginWithPopup(
    options?: Partial<AuthorizationParams>,
    config?: PopupConfigOptions
  ) {
    options = options || {};
    config = config || {};
    console.log('config -->',config)
    if (!config.popup) {
      config.popup = openPopup('');

      if (!config.popup) {
        throw new Error(
          'Unable to open a popup for loginWithPopup - window.open returned `null`'
        );
      }
    }
    console.log('config after-->',config)
    const params = await createCodeAuthorizationParams(
      this.config,
      { ...options, response_mode: 'web_message', display: 'popup' },
      () => this.createAndSaveNonce(),
      null
    );
    console.log('params -->',params)
    const url = createUrl(
      this.config.authorizeUrl,
      pickAvailabelAuthorizeParams(params)
    );
    console.log('url -->',url)
    config.popup.location.href = url;

    const codeResult = await runPopup({
      ...config,
      timeoutInSeconds:
        config.timeoutInSeconds || DEFAULT_AUTHORIZE_TIMEOUT_IN_SECONDS,
    });

    if (params.state !== codeResult.state) {
      throw new ErrorBase('state_mismatch', 'Invalid state');
    }
    return this.getAndSaveTokenFromCode(
      { code: codeResult.code!, codeVerifier: params.code_verifier },
      {}
    );
  }
  public createAndSaveNonce(): Promise<string> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    return createNonce().then(function (nonce: any) {
      // Use localStorage for nonce if possible
      // localStorage is the only storage who survives a
      // redirect in ALL browsers (also IE)
      // Otherwiese we'd force teams who have to support
      // IE into using localStorage for everything
      if (
        that.saveNoncesInLocalStorage &&
        typeof window['localStorage'] !== 'undefined'
      ) {
        localStorage.setItem('nonce', nonce);
      } else {
        that._storage.setItem('nonce', nonce);
      }
      return nonce;
    });
  }
  debug(...args: any[]): void {
    if (this.config.showDebugInformation) {
      this.logger.debug(args);
    }
  }
  public logOut(
    customParameters: boolean | Record<string, any> = {},
    state = ''
  ): void {
    let noRedirectToLogoutUrl = false;
    if (typeof customParameters === 'boolean') {
      noRedirectToLogoutUrl = customParameters;
      customParameters = {};
    }

    const id_token = this.getIdToken();
    this.cleanUpStorage();
    this.silentRefreshSubject = null;

    this.pushEvent(new RedocOAuthInfoEvent('logout'));

    if (!this.config.logoutUrl) {
      return;
    }
    if (noRedirectToLogoutUrl) {
      return;
    }

    // if (!id_token && !this.postLogoutRedirectUri) {
    //   return;
    // }

    let logoutUrl: string;

    if (!this.validation.validateUrlForHttps(this.config.logoutUrl)) {
      throw new Error(
        "logoutUrl  must use HTTPS (with TLS), or config value for property 'requireHttps' must be set to 'false' and allow HTTP (without TLS)."
      );
    }

    // For backward compatibility
    if (this.config.logoutUrl.indexOf('{{') > -1) {
      logoutUrl = this.config.logoutUrl
        .replace(/\{\{id_token\}\}/, encodeURIComponent(id_token || 'id_token'))
        .replace(
          /\{\{client_id\}\}/,
          encodeURIComponent(this.config.clientId || 'client_id')
        );
    } else {
      const params: Record<string, string> = {};

      if (id_token) {
        params['id_token_hint'] = id_token;
      }

      const postLogoutUrl =
        this.config.postLogoutRedirectUri ||
        (this.config.redirectUriAsPostLogoutRedirectUriFallback &&
          this.config.redirectUri) ||
        '';
      if (postLogoutUrl) {
        params['post_logout_redirect_uri'] = postLogoutUrl;

        if (state) {
          params['state'] = state;
        }
      }

      for (const key in customParameters) {
        params[key] = customParameters[key];
      }

      logoutUrl = createUrl(this.config.logoutUrl, params);
    }
    this.openUri(logoutUrl);
  }
  public openUri(uri: string): void {
    console.log('openUri', uri);
    location.href = uri;
  }
  public cleanUpStorage(): void {
    this._storage.removeItem(this.accessTokenSet.name);
    this._storage.removeItem(this.idTokenSet.name);
    this._storage.removeItem(this.refreshTokenSet.name);

    if (this.saveNoncesInLocalStorage) {
      localStorage.removeItem('nonce');
      localStorage.removeItem('PKCE_verifier');
    } else {
      this._storage.removeItem('nonce');
      this._storage.removeItem('PKCE_verifier');
    }

    this._storage.removeItem(this.accessTokenSet.expiresAt);
    this._storage.removeItem(this.idTokenSet.claimsObj);
    this._storage.removeItem(this.idTokenSet.expiresAt);
    this._storage.removeItem(this.idTokenSet.storedAt);
    this._storage.removeItem(this.accessTokenSet.storedAt);
    this._storage.removeItem('granted_scopes');
    this._storage.removeItem('session_state');
    if (this.config.customTokenParameters) {
      this.config.customTokenParameters.forEach((customParam) =>
        this._storage.removeItem(customParam)
      );
    }
  }
}
