import { isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable, from, of, tap } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AUTH_COOKIE_READER } from '..';
import { AUTH_NAVIGATION_HANDLER } from '../auth-navigation-handler';
import { AUTH_PROVIDER } from '../auth.token';
import { OauthService } from '../oauth';

@Injectable()
export class SSONoAuthGuard {
  _authService = inject(AUTH_PROVIDER);
  platformId = inject(PLATFORM_ID);
  isServer = isPlatformServer(this.platformId);
  _router = inject(AUTH_NAVIGATION_HANDLER);
  _oauthService = inject(OauthService)
  _cookieReader = inject(AUTH_COOKIE_READER)
  /**
   * Constructor
   */

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._check();
  }

  /**
   * Can activate child
   *
   * @param childRoute
   * @param state
   */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._check();
  }

  /**
   * Can load
   *
   * @param route
   * @param segments
   */
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._check();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @private
   */
  private _check(): Observable<boolean> {
    return from(this._oauthService.loadDiscoveryDocumentAndTryLogin()) .
    pipe(
      tap(()=> {

        if (
          this._oauthService.hasValidAccessToken() &&
          this.isLogin(this._oauthService.getAccessToken()!)
        ) {
          this._authService.accessToken = this._oauthService.getAccessToken()!;
          this._authService.refreshToken = this._oauthService.getRefreshToken()!;

        }
      }),
      switchMap(()=> {
        return this._authService.check().pipe(
          switchMap((authenticated) => {
            console.log('noAuth -->', authenticated);
            // If the user is authenticated...
            if (authenticated) {
              // Prevent the access
              return of(false).pipe(
                tap(() => {
                  let stateUrl = this._oauthService.state;
                  console.log('stateUrl',stateUrl)
          if (stateUrl.startsWith('/') === false) {
            stateUrl = decodeURIComponent(stateUrl);
          }
          console.log(
            `There was state of ${this._oauthService.state}, so we are sending you to: ${stateUrl}`
          );
          this._router.navigateByUrl(stateUrl);
                  // Redirect to the root
                  // this._router.navigate(['']);
                })
              );
            }
    
            // Allow the access
            return of(true);
          })
        );
      })
    )
    // console.log('this._authService', this._authService);
    // Check the authentication status
   
  }
  isLogin(currentToken: string): boolean {
    const doc = this._oauthService.getDiscoveryDocument();
    if(!doc){
      throw new Error('Discovery document must be loaded')
    }
    const {session} = doc;
    const c_user_name = [session ? session.prefix : "", "redoc.c_user"]
    .filter((item) => !!item)
    .join("_");
    const cuid = this._cookieReader.get(c_user_name)
    const { userId } = decodeJWT(currentToken);
    return String(userId) === String(cuid);
  }
}
function decodeJWT(token:string) {
  const base64Url = token.split('.')[1];
  if (!base64Url) {
      return false;
  }
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  try {
      const jsonPayload = decodeURIComponent(
          atob(base64)
              .split('')
              .map((c) => {
                  return (
                      '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                  );
              })
              .join('')
      );
      return JSON.parse(jsonPayload);
  } catch (error) {
      return false;
  }
}