import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { TranslocoModule } from '@ngneat/transloco';
import { FormBuilderComponent } from '@redocco/material-ui';

@Component({
  selector: 'redoc-layout-list',
  standalone: true,
  imports: [CommonModule,MatSidenavModule,TranslocoModule,FormBuilderComponent],
  templateUrl: './layout-list.component.html',
  styleUrl: './layout-list.component.scss',
})
export class LayoutListComponent {
  drawerMode: 'side' | 'over' | 'push' = 'over';
  isDrawerOpened = false;
  private _cdr = inject(ChangeDetectorRef)
  drawerOpenedChange(isOpened: boolean): void {
    this.isDrawerOpened = isOpened;
    this._cdr.detectChanges();
  }
}
