import { Injectable } from '@angular/core';
import { ApiService, IApiOption, IApiQuery } from '@shared/core/api';
import { BusinessUnitModel } from '@shared/data-access/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BusinessUnitApiService {
    static SERVICE_BUSINESS_UNIT = 'business-units';
    static BUSINESS_UNIT_DETAIL = 'business-units/:id';
    static GET_USERS = 'business-units/users';
    static GET_USERS_FOR_UPDATE_ASSIGNMENT =
        'business-units/user/for-update-assignment';
    static UPDATE_USER_ASSIGNMENT = 'business-units/update-user-assignment';
    static ADD_USERS = 'business-units/add-users';

    constructor(private apiService: ApiService) { }
    get(id:number, option?: IApiOption):Observable<BusinessUnitModel>{
      return this.apiService.get(BusinessUnitApiService.BUSINESS_UNIT_DETAIL,{id},option).pipe(
        map(item => BusinessUnitModel.fromJson(item))
      )
    }

    // getBusinessUnitUsers(query:IApiQuery, option?: IApiOption): Observable<UserGroupModel[]> {
    //     return this.apiService
    //         .get(BusinessUnitApiService.GET_USERS, query, option)
    //         .pipe(
    //             map(res => res.map(item => UserModel.fromJson(item)))
    //         );
    // }

    // getUsersForUpdateAssignment() {
    //     return this.apiService.get(
    //         BusinessUnitApiService.GET_USERS_FOR_UPDATE_ASSIGNMENT
    //     );
    // }

    // updateUserAssignment(userIds) {
    //     return this.apiService.post(
    //         BusinessUnitApiService.UPDATE_USER_ASSIGNMENT,
    //         userIds
    //     );
    // }

    // create(data): Observable<BusinessUnitModel> {
    //     return this.apiService.post(BusinessUnitApiService.SERVICE_BUSINESS_UNIT, data).pipe(
    //         map((res: BusinessUnitInterface) => BusinessUnitModel.fromJson(res))
    //     );
    // }

    // update(data: object): Observable<BusinessUnitModel> {
    //     return this.apiService.patch(BusinessUnitApiService.SERVICE_BUSINESS_UNIT, data).pipe(
    //         map((res: BusinessUnitInterface) => BusinessUnitModel.fromJson(res))
    //     );
    // }

    // addUsers(data) {
    //     return this.apiService.post(BusinessUnitApiService.ADD_USERS, data);
    // }
}
