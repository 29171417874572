import { APP_INITIALIZER, Injectable, makeEnvironmentProviders } from '@angular/core';
import {
  IRedocOauth2Config,
  RedocOAuthLogger,
  RedocOAuthStorage
} from '@redocco/oauth2-redoc-connect';
import { OauthService } from './oauth.service';
import { REDOC_OAUTH2_CONFIG } from './token';
import { Observable, from } from 'rxjs';
@Injectable()
export class MemoryStorage implements RedocOAuthStorage {
  private data = new Map<string, string>();

  getItem(key: string): string {
    return this.data.get(key) as string;
  }

  removeItem(key: string): void {
    this.data.delete(key);
  }

  setItem(key: string, data: string): void {
    this.data.set(key, data);
  }
}
export function provideOauthClient(
  config: Partial<IRedocOauth2Config>,
  storage?: Storage
) {
  return makeEnvironmentProviders([
    OauthService,
    { provide: RedocOAuthLogger, useFactory: createDefaultLogger },
    {
      provide: RedocOAuthStorage,
      useFactory: storage ? () => storage : createDefaultStorage,
    },
    {
      provide: REDOC_OAUTH2_CONFIG,
      useValue: config,
    },
  ]);
}
export function createDefaultLogger() {
  return console;
}
export function createDefaultStorage() {
  console.log('sessionStorage', sessionStorage);
  return typeof sessionStorage !== 'undefined'
    ? sessionStorage
    : new MemoryStorage();
}
// function initializeAppFactory(oauthService: OauthService): () => Observable<any> {
//   return () => from(oauthService.loadDiscoveryDocument())
//  }