import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable, from, of, tap } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AUTH_COOKIE_READER } from '..';
import { AUTH_PROVIDER } from '../auth.token';
import { AuthUtils } from '../auth.utils';
import { OauthService } from '../oauth';

@Injectable()
export class SSOAuthGuard {
  _authService = inject(AUTH_PROVIDER);
  _oauthService = inject(OauthService)
  _cookieReader = inject(AUTH_COOKIE_READER)
  /**
   * Constructor
   */
  constructor(private _router: Router) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    return this._check(redirectUrl);
  }

  /**
   * Can activate child
   *
   * @param childRoute
   * @param state
   */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
      const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
      return this._check(redirectUrl);
  }

  /**
   * Can load
   *
   * @param route
   * @param segments
   */
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._check('/');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @private
   */
  private _check(redirectURL: string): Observable<boolean> {
    return from(this._oauthService.loadDiscoveryDocumentAndTryLogin()).pipe(
      switchMap(()=> {
        if (
          this._oauthService.hasValidAccessToken()
        ) {
          this._authService.accessToken = this._oauthService.getAccessToken()!;
          this._authService.refreshToken = this._oauthService.getRefreshToken()!;
    
        }
        if(!!this._authService.accessToken && !this.isLoginWithSameUser(this._authService.accessToken)){
          this._authService.accessToken = '';
          this._authService.refreshToken = ''
        }
        
        // Check the authentication status
        return this._authService.check().pipe(
          switchMap((authenticated) => {
            console.log('this._authService',authenticated);
            // If the user is authenticated...
            if (!authenticated) {
              // Prevent the access
              return of(false).pipe(
                tap(() => {
                  this._oauthService.loginWithRedirect(redirectURL)
                  // }
                })
              );
            }
    
            // Allow the access
            return of(true);
          })
        );
      })
    )
    
   
  }
  isLoginWithSameUser(currentToken: string): boolean {
    const doc = this._oauthService.getDiscoveryDocument();
    if(!doc){
      throw new Error('Discovery document must be loaded')
    }
    const {session} = doc;
    const c_user_name = [session ? session.prefix : "", "redoc.c_user"]
    .filter((item) => !!item)
    .join("_");
    const cuid = this._cookieReader.get(c_user_name)
    const { userId } =AuthUtils.decodeToken(currentToken);
    return String(userId) === String(cuid);
  }
}
